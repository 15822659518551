import { Assets } from "./assets.model";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { DataService } from "../data.service";
import { tap } from "rxjs/operators";
import { UtilService } from "../util.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AssetService {
  ga4Ids: string[] = ['G-C0CYKRZ7NV'];
  private merchantAssets: Assets;    
  constructor(private dataService: DataService) {}

  getMerchantAssets(): Observable<Assets> {
    if (this.merchantAssets == null) {
      return this.dataService.getAssets().pipe(
        tap((assets) => {
          this.merchantAssets = assets;
          //set theme if color selected
          if (this.merchantAssets.primaryColor) {
            let html = document.querySelector("html");
            html.style.setProperty(
              "--ion-color-primary",
              this.merchantAssets.primaryColor
            );
          }
          if(this.merchantAssets.currencyCode && this.merchantAssets.currencyCode != "") 
          {
            sessionStorage.setItem("currencyCode", this.merchantAssets.currencyCode);            
          }   
          if(this.merchantAssets.selectLocationText && this.merchantAssets.selectLocationText != "" && 
            this.merchantAssets.selectLocationUrl && this.merchantAssets.selectLocationUrl != "") 
          {
            sessionStorage.setItem("selectLocationText", this.merchantAssets.selectLocationText);
            sessionStorage.setItem("selectLocationUrl", this.merchantAssets.selectLocationUrl);
          }  
          if(this.merchantAssets.hyperLinkText && this.merchantAssets.hyperLinkText != "" && 
            this.merchantAssets.hyperLinkUrl && this.merchantAssets.hyperLinkUrl != "") 
          {
            sessionStorage.setItem("hyperLinkText", this.merchantAssets.hyperLinkText);
            sessionStorage.setItem("hyperLinkUrl", this.merchantAssets.hyperLinkUrl);
          }          
          if(this.merchantAssets.placeHolderImgUrl && this.merchantAssets.placeHolderImgUrl != "") 
          {
            sessionStorage.setItem("placeHolderImgUrl", this.merchantAssets.placeHolderImgUrl);
          }
          if(this.merchantAssets.googleTagManagerAppId && this.merchantAssets.googleTagManagerAppId != "") 
          {
            sessionStorage.setItem("googleTagManagerAppId", this.merchantAssets.googleTagManagerAppId);
          }
          if(this.merchantAssets.facebookPixelAppId && this.merchantAssets.facebookPixelAppId != "") 
          {
            sessionStorage.setItem("facebookPixelAppId", this.merchantAssets.facebookPixelAppId);
          }
          if(this.merchantAssets.facebookPixelDomainVerificationContent && this.merchantAssets.facebookPixelDomainVerificationContent != "") 
          {
            sessionStorage.setItem("facebookPixelDomainVerificationContent", this.merchantAssets.facebookPixelDomainVerificationContent);
          }
          if(this.merchantAssets.googleAnalyticsMesurementId && this.merchantAssets.googleAnalyticsMesurementId != "") 
          {
            sessionStorage.setItem("googleAnalyticsMesurementId", this.merchantAssets.googleAnalyticsMesurementId);
            if (this.ga4Ids.indexOf(this.merchantAssets.googleAnalyticsMesurementId) === -1) {
              this.ga4Ids.push(this.merchantAssets.googleAnalyticsMesurementId);
            }  
          }
          if (this.merchantAssets.customCss && this.merchantAssets.customCss.length > 0) {
            this.merchantAssets.customCss.forEach( (item, index) => {
              sessionStorage.setItem(item.key, item.value);
              let html = document.querySelector("html");
              if (item.key == '--ion-toolbar-background') {               
                html.style.setProperty("--ion-toolbar-background", item.value );
              }
              if (item.key == '--ion-toolbar-border') {               
                html.style.setProperty("--ion-toolbar-border", item.value );
              }
              if (item.key == '--ion-toolbar-icon') {               
                html.style.setProperty("--ion-toolbar-icon", item.value );
              }
            });

          }
          if(this.merchantAssets.logoUrl && this.merchantAssets.logoUrl != "") 
          {
            let logoImageUrl = this.makeImageUrl(this.merchantAssets.logoUrl);
            sessionStorage.setItem("logoUrl", logoImageUrl);
          }
          if(this.merchantAssets.showUtensilsRequired != undefined) 
          {  
            sessionStorage.setItem("showUtensilsRequired", this.merchantAssets.showUtensilsRequired.toString());
          }
        })
      );
    } else {
      return of(this.merchantAssets);
    }
  }

  getTranslationJSON() {
    return this.merchantAssets.translationLangJSONs;
  }

    getSelectedLanguageJSON() {         
        let translationLangJSON = this.merchantAssets.translationLangJSONs.find(t => t.lang == this.selectedLanguage);
        if (translationLangJSON) {
            return translationLangJSON.translationJSON;
        }
        return undefined;        
    }

    getLanguageJSON(lang: string) {
        let translationLangJSON = this.merchantAssets.translationLangJSONs.find(t => t.lang == lang);
        if (translationLangJSON) {
            return translationLangJSON.translationJSON;
        }
        return undefined;      
    }

    get selectedLanguage(): string {
        let lang = sessionStorage.getItem('selectedLanguage');
        return lang ? lang : 'english';
    }

    set selectedLanguage(lang: string) {
        sessionStorage.setItem('selectedLanguage', lang);
    }

  getCustomCSS(cssStyles: any, title: string) {
    const link = document.createElement("link");
    link.href = `data:text/css;base64,${btoa(cssStyles)}`;
    link.type = "text/css";
    link.rel = "stylesheet";
    link.id = title + "_CUSTOM_CSS";
    $('link[id="'+link.id+'"]').remove();
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  setFacebookPixelVerificationMeta(content: any) {
    const meta = document.createElement("meta");
    meta.name = "facebook-domain-verification";
    meta.content = content;
    meta.id = "PIXEL_DOMAIN_VERIFICATION";
    $('meta[id="'+meta.id+'"]').remove();
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
  
  makeImageUrl(name) {
    let endpoint = environment.PORTAL_END_POINT;
    return endpoint + "/FileManager/Index?imageName=" + name;
  }
  
}
