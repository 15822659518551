import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Platform, LoadingController } from '@ionic/angular';
import { CustomerSessionService } from '../customer-session.service';
import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MembershipRoutingService } from '../membership-routing.service';
import { environment } from 'src/environments/environment';
import { MenuController } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CatalogService } from '../catalog.service';
import { AlertPopupComponent } from '../common/alert-popup/alert-popup.component';
import { CartService } from '../cart.service';
import Swal from 'sweetalert2';
import { UtilService } from '../util.service';

@Component({
    selector: 'app-memberships',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class MembershipsComponent implements OnInit {
    dashboardLogoImageUrl;
    dashboardLabel;
    isMobile;
    loggedIn: boolean = false;
    ssoId: string;
    waitForProcess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loading: any;

    @ViewChild('alertPopup') alertPopup: AlertPopupComponent;

    public appPages = [
        {
            title: 'Memberships',
            url: 'list',
            icon: 'people'
        },
        // {
        //     title: 'Payment',
        //     url: 'payment-manage',
        //     icon: 'card'
        // }
    ];

    constructor(private platform: Platform,
        private customerSession: CustomerSessionService,
        private dataService: DataService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private membershipRouting: MembershipRoutingService,
        public menuCtrl: MenuController,
        private spinner: NgxSpinnerService,
        private util: UtilService) {

        this.route.queryParams.subscribe(params => {
            this.ssoId = params['sso'];
        });
        this.validateAndUpdateMerchant();

        try {
            parent.postMessage({action: 'showHomeButton'}, sessionStorage.getItem("hostingapp"));
        }
        catch (error) {
           console.log("Handled Non-IFrame Response");
        }       
    }

    async ngOnInit() {
        sessionStorage.setItem('memberships_module', "true");
        this.authService.isAuthenticated.subscribe(x => { this.loggedIn = x })
        if (!this.loggedIn) {
            // ensures "sign out" option appears in web version if id_token is present
            let hasToken: boolean = sessionStorage.getItem('id_token') ? true : false;
            if (hasToken) {
                this.loggedIn = true;
            }
        }
        console.log("login status : " + this.loggedIn);
        this.customerSession.mobileMode.subscribe((data) => {
            if (data) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
                this.spinner.show();
                this.spinner.hide(); // resolves loading spinner when accessing memberships via web link (temp fix - need to investigate app component show/hide)
            }
        });
        this.postIfMobileDevice();

        await this.dataService.getDashboardData().toPromise().then((data) => {
            if (data.merchantName) {
                this.dashboardLabel = data.merchantName;
                sessionStorage.setItem("merchantName", this.dashboardLabel);
            }
            if (data.disclaimer) {
                sessionStorage.setItem("disclaimer", data.disclaimer);
            }
            if (data.autoShowDisclaimer == true) {
                sessionStorage.setItem("auto_show_disclaimer", "1");
            }
            if (data.logoName) {
                this.dashboardLogoImageUrl = this.makeImageUrl(data.logoName);
                sessionStorage.setItem("logoUrl", this.dashboardLogoImageUrl);
            }
            if (data.hideItemPageSpecialInstruction == true) {
                sessionStorage.setItem("hide_item_page_special_instruction", "1");
            }
            else {
                sessionStorage.removeItem("hide_item_page_special_instruction");
            }
        }, (error) => {
            console.log(error);
        }).then(() => {
            this.waitForProcess.subscribe(wait => {
                if (!wait) {
                    if (this.isMobile) {
                        let membershipIdFromMobileApp: number = +sessionStorage.getItem("membershipIdFromMobileApp");
                        sessionStorage.removeItem("membershipIdFromMobileApp");
                        if (membershipIdFromMobileApp) {
                            this.membershipRouting.goToMembershipDetails(membershipIdFromMobileApp);
                        } else {
                            this.membershipRouting.goToMembershipList();
                        }
                    } else {
                        // checks if URL contains params for updating suspended membership
                        var queryParams = this.route.snapshot.queryParams;
                        if (queryParams.membershipId && queryParams.updatePayment == "true") {
                            this.membershipRouting.membershipToUpdate = queryParams.membershipId;
                            this.membershipRouting.updatePaymentRequired = true;
                        } 

                        // checks redirect variables to determine where to navigate to when memberships dashboard is created.
                        // dashboard always loads twice when initally trying to access app via web link (first one is destroyed, second one will determine redirect)
                        if (this.membershipRouting.redirectToDetails) {
                            var membershipId: number = this.membershipRouting.membershipId;
                            sessionStorage.removeItem("redirectToDetails");
                            sessionStorage.removeItem("membershipId");
                            this.membershipRouting.goToMembershipDetails(membershipId);
                        } else if (this.membershipRouting.redirectToLogin) {
                            sessionStorage.removeItem("updatePaymentRequired");
                            sessionStorage.removeItem("redirectToLogin");
                            this.membershipRouting.goToLogin();
                        } else {
                            this.membershipRouting.goToMembershipList();
                        }
                    }
                }
            });
        });
    }

    onMenuOpen() {
       /* let selectedLocationId = sessionStorage.getItem("locationId")
        if (!selectedLocationId) {
            this.paymentMenu.hidden = true;
        }
        else {
            this.paymentMenu.hidden = false;
        }
        this.showChangeCatalog();*/
    }

    goToLogin() {
        this.membershipRouting.goToLogin();
    }

    signOut() {
        this.authService.logout().subscribe((result) => { console.log(result) });
    }

    toggleMenu() {
        this.menuCtrl.toggle(); //Add this method to your button click function
    }

    makeImageUrl(name) {
        let endpoint = environment.PORTAL_END_POINT;
        return endpoint + "/FileManager/Index?imageName=" + name;
    }

    goTo(url) {
        if (url == "list") {
            this.membershipRouting.goToMembershipList();
        } 
        // else if (url == "payment-manage") {
        //     this.membershipRouting.goToPaymentManage();
        // }
    }

    ionViewDidEnter() {
        console.log("entered side menu");
    }

    validateAndUpdateMerchant() {
        let guid = this.route.snapshot.params['guid'];
        let existingGuid = sessionStorage.getItem('guid');

        if (existingGuid != null && existingGuid != guid) {
            sessionStorage.clear();
        }
        sessionStorage.setItem('guid', guid);


        this.checkAndClearData(guid);

        if (this.ssoId && this.ssoId != "") {
            this.authService.removeUserData();
            this.waitForProcess.next(true);
            this.authService.authenticateCustomerBySso(this.ssoId).toPromise().then(
                (response: any) => {
                    this.spinner.hide();
                    this.waitForProcess.next(false);
                },
                (err) => {
                    this.spinner.hide();
                    this.alertPopup.show("Error!", "Unable to Login. Please try again.");
                }
            );
        } else if (sessionStorage.getItem('id_token') == null && localStorage.getItem(guid)) {
            this.authService.getNewRefreshToken().toPromise().then(
                (tokenresponse: any) => {
                    if (tokenresponse) {
                        sessionStorage.setItem('id_token', localStorage.getItem(guid));
                        this.authService.isAuthenticated.next(true);
                    }
                    else {
                        this.authService.logout();
                    }
                },
                (err) => {
                    this.authService.logout();
                    console.log(err);
                }
            );
        }
        this.membershipRouting.setGuid(guid);
    }

    checkAndClearData(guid) {
        let storageGuid = sessionStorage.getItem('guid');
        if (this.customerSession.isMobile() == false && storageGuid && storageGuid != guid) {
            console.log('GUID DIFFERENT - session guid stored and the current url guid are different: ' + storageGuid + ", " + guid);
            sessionStorage.clear();
        }
    }

    alertPopupClosed() {
        this.waitForProcess.next(false);
    }

    backToMobileApp(event) {
        parent.postMessage({action: 'goToHostingApp'}, sessionStorage.getItem("hostingapp"));
    }

    showHomeButton() {
       return this.util.showHomeButton$;
    }

    /* Accessibility Implementation for Key Press */
    eventHandler(event) 
    {
        try {
            if (event.currentTarget.id == "closeMenu" && event.keyCode == 13) {
                this.menuCtrl.toggle();
            }   
            if (event.currentTarget.id == "menu-item-1" && event.keyCode == 13) {
                this.menuCtrl.toggle().then(() => {
                    this.membershipRouting.goToMembershipList();
                });                
            }                              
        } catch (error) {
            
        }      
    }

    postIfMobileDevice() {
        var mobileCheck = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);   
        // Fix for iPadOS not being detected as mobile on safari (known bug)
         if (!mobileCheck) {
           let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
           if (isIpad) {
            mobileCheck = true;
           }
         } 
         if (mobileCheck) {
            console.log('POSTING MESSAGE TO CHECK FOR MOBILE DEVICE AND APPLE PAY');
            parent.postMessage({action: 'getPlatform'}, sessionStorage.getItem("hostingapp")); 
            parent.postMessage({action: 'getApplePayAvailable'}, sessionStorage.getItem("hostingapp"));    
            parent.postMessage({action: 'getGooglePayAvailable'}, sessionStorage.getItem("hostingapp"));    
         }
      }
}
