import { Injectable } from '@angular/core';
import { AssetService } from './merchant-assets/asset.service';
import { Assets } from './merchant-assets/assets.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticService {
  private gaScriptLoaded = false;
  private merchantAssets: Assets
  eventTracker: Map<string, Set<string>> = new Map();
  constructor(private assetService: AssetService, private util: UtilService) { }

  sendEvent(action, label){
    //get merchant name via assets
    this.assetService.getMerchantAssets()
        .subscribe((assets) => { 
          this.merchantAssets = assets;
    });
    
    let category = "Unknown";
    //check if it exists
    if (this.merchantAssets && this.merchantAssets.headerName){
      category = this.merchantAssets.headerName;
    } 

    (<any>window).ga('send', 'event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });

    this.sendEventToAllGa4Ids(category, action, label, true);

    let mobileAppResult = this.util.checkIfMobileApp();

    if (mobileAppResult.isMobile && mobileAppResult.isMobileApp) {
      console.log('POSTING MESSAGE TO TRACK GA4 EVENT TO MOBILE APP');
      parent.postMessage(
        {
          action: 'send_ga_event',
          event_name: action,
          page_path: '',
          send_to_default: false
        }, 
        sessionStorage.getItem("hostingapp")
      ); 
    }

  }
  
  sendEventWithValue(action, label, value){
    //get merchant name via assets
    this.assetService.getMerchantAssets()
        .subscribe((assets) => { 
          this.merchantAssets = assets;
    });
    
    let category = "Unknown";
    //check if it exists
    if (this.merchantAssets && this.merchantAssets.headerName){
      category = this.merchantAssets.headerName;
    }    

    (<any>window).ga('send', 'event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value
    });

    this.sendEventWithValueToAllGa4Ids(category, action, label, value, true);
  }

  sendEventToAllGa4Ids(category: string, action: string, label: string, allowRepeatedEvents = false) {
    this.assetService.ga4Ids.forEach(ga4Id => {
      // Initialize event tracking for the GA4 ID if it doesn't exist
      if (!this.eventTracker.has(ga4Id)) {
        this.eventTracker.set(ga4Id, new Set<string>());
      }

      const trackerSet = this.eventTracker.get(ga4Id); // Get the tracker for the current GA4 ID

      // Create a unique event key (you can also include a dynamic value like a timestamp or product ID)
      const eventKey = `${category}_${action}_${label}`;

      // Check if repeated events are allowed or if the event hasn't been fired yet
      if (allowRepeatedEvents || (trackerSet && !trackerSet.has(eventKey))) {
        // Set the GA4 ID as the active configuration
        window['gtag']('config', ga4Id);
        console.log(`Sending ${eventKey} to => ${ga4Id}`);

        // Send the event to the specific GA4 ID
        window['gtag']('event', action, {
          event_category: category,
          event_label: label,
        });

        // Mark this event as fired for this GA4 ID if repeated events aren't allowed
        if (!allowRepeatedEvents) {
          trackerSet.add(eventKey);
        }
      } else {
        console.log(`Event ${eventKey} has already been sent to ${ga4Id}, skipping.`);
      }
    });
  }

  sendEventWithValueToAllGa4Ids(category: string, action: string, label: string, value: any, allowRepeatedEvents = false) {
    this.assetService.ga4Ids.forEach(ga4Id => {
      // Initialize event tracking for the GA4 ID if it doesn't exist
      if (!this.eventTracker.has(ga4Id)) {
        this.eventTracker.set(ga4Id, new Set<string>());
      }

      const trackerSet = this.eventTracker.get(ga4Id); // Get the tracker for the current GA4 ID

      // Create a unique event key (you can also include a dynamic value like a timestamp or product ID)
      const eventKey = `${category}_${action}_${label}_${value}`;

      // Check if repeated events are allowed or if the event hasn't been fired yet
      if (allowRepeatedEvents || (trackerSet && !trackerSet.has(eventKey))) {
        // Set the GA4 ID as the active configuration
        window['gtag']('config', ga4Id);
        console.log(`Sending ${eventKey} to => ${ga4Id}`);

        // Send the event to the specific GA4 ID
        window['gtag']('event', action, {
          event_category: category,
          event_label: label,
          value: value,
        });

        // Mark this event as fired for this GA4 ID if repeated events aren't allowed
        if (!allowRepeatedEvents) {
          trackerSet.add(eventKey);
        }
      } else {
        console.log(`Event ${eventKey} has already been sent to ${ga4Id}, skipping.`);
      }
    });
  }
}
