import { Component, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth/auth.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FacebookPixelService } from './facebook-pixel.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { Platform } from '@ionic/angular';
import { Location } from "@angular/common";
import { UtilService } from './util.service';
import { TranslateService } from '@ngx-translate/core';
import { AssetService } from './merchant-assets/asset.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: []
})
export class AppComponent implements OnInit{
 

  awaitingAuth = true;
  title = 'order-app'; 
  constructor(private util: UtilService,
              private authService: AuthService,
              private spinner: NgxSpinnerService, private router: Router, 
              private fbPixelService: FacebookPixelService, 
              private gtmService: GoogleTagManagerService,
              private platform: Platform,
              private location: Location,
              private assetService: AssetService,
              private translate: TranslateService) {
                this.router.events.subscribe(async event => {
                  if (event instanceof NavigationEnd) {
                    let pageUrl = event.urlAfterRedirects;
                    if (pageUrl.indexOf('?') > -1){
                      pageUrl = pageUrl.substr(0, pageUrl.indexOf('?'));
                    }
                    (<any>window).ga('set', 'page', pageUrl);
                    (<any>window).ga('send', 'pageview');
                    
                    this.fbPixelService.loadFaceBookPixelDomainVerificationContent();
                    this.fbPixelService.loadFaceBookPixel();
                    this.gtmService.loadGoogleTagManager();   
                    this.gtmService.trackSinglePageView(event);  
                              
                  }
                });
  }
  
              

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    console.log("ngOnInit - app component");
    
    let url = window.location.href;
    console.log("About to check Domain Info");
    await this.authService.domainInfo(url).then((result) => {
        console.log(`Got result from Domain Info`);
        if (result && result.url) {
          console.log(result);
          console.log(`About to Redirect to Url => ${result.url}`);
          window.location.href = result.url;
        }
    });
    console.log("Checking User Authenticated");

    this.authService.isAuthenticated.subscribe((authenticated) => { 
      if(authenticated != null) { 
        this.spinner.hide();
        console.log("ngOnInit - app componenet - authenticated");
      }
    })
    console.log("ngOnInit - complete");
  }  

  
}
