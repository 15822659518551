import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplePaymentData } from '../models/apple-payment-data';

@Component({
  selector: 'app-square-payment-popup',
  templateUrl: './square-payment-popup.component.html',
  styleUrls: ['./square-payment-popup.component.scss']
})
export class SquarePaymentPopupComponent implements OnInit {

  @Input() public squareLocationId: string;
  @Input() public squareAppId: string;
  @Input() public paymentType: string;
  @Input() public applePaymentData: ApplePaymentData;

  iframeUrl: string;
  public sanitizedUrl: SafeResourceUrl;
  squareWalletPayEndPoint: string = environment.SQUARE_WALLET_PAY_END_POINT;


  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    const timestamp = new Date().getTime();
    this.iframeUrl = `${environment.SQUARE_WALLET_PAY_END_POINT}?appid=${this.squareAppId}&locationid=${this.squareLocationId}&paymentType=${this.paymentType}&websiteURL=${this.squareWalletPayEndPoint}&_=${timestamp}`.trim();
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
    console.log(this.iframeUrl);
    timer(500).subscribe((x) => {
      let iframe = document.getElementById('squarePaymentIframe') as HTMLIFrameElement;
      const postMessageToIframe = () => {
        const msg = { applePaymentData: this.applePaymentData };
        if (iframe.contentWindow) {
          iframe.contentWindow.postMessage(msg, this.iframeUrl);
        }
      };
      if (iframe.contentWindow) {
        postMessageToIframe();
      } else {
        iframe.onload = postMessageToIframe;
      }
    });    
    
  }
  close() {
    this.modalService.dismissAll();
  }

  orderPageLoaded() {

  }
}
