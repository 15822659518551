import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { ApplePaymentData } from './models/apple-payment-data';
import { PaymentService } from './payment.service';
import { PaymentAction } from './models/enum';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SquarePaymentPopupComponent } from './square-payment-popup/square-payment-popup.component';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SquareApplePayService {
  private applePayAvailable$ = new BehaviorSubject<boolean>(false);
  private squareToken$ = new BehaviorSubject<any>(null);
  private squareVerificationToken$ = new BehaviorSubject<any>(null);
  private applePaymentData$ = new BehaviorSubject<ApplePaymentData>(null);
  squareLocationId$ = new BehaviorSubject<string>(null);
  squareAppId$ = new BehaviorSubject<string>(null);
  isMobile: boolean;
  isMobileApp: boolean;
  squareModalReference: NgbModalRef;

  constructor(private paymentService: PaymentService, private modalService: NgbModal, private util: UtilService) 
  {
    this.util.postAppSpecificRequestsToFrame();
    this.checkIfMobileApp();
    console.log('IS MOBILE APP: ' + this.isMobileApp);
  }

  checkIfMobileApp() {
    let mobileAppResult = this.util.checkIfMobileApp();
    this.isMobile = mobileAppResult.isMobile;
    this.isMobileApp = mobileAppResult.isMobileApp;   
  }

  checkApplePaySessionAvailable() {
    if ((<any>window).ApplePaySession) {
      console.log('The Apple Pay JS API is available');
      this.applePayAvailable$.next(true);
    }   
    else {
      console.log('No Appe Pay Session Found');
      this.applePayAvailable$.next(false);
    }
  }

  getApplePayAvailable() {
    return this.applePayAvailable$.getValue();
  }

  processApplePay(applePaymentData: ApplePaymentData, appId: string, locationId: string) {       
    var merchantId = this.paymentService.getMerchantId();
    var merchantLocationId = this.paymentService.getMerchantLocationId();     
    console.log('merchantId => ' + merchantId);
    console.log('merchantLocationId => ' + merchantLocationId);
    this.goToNewTab(applePaymentData, appId, locationId, 'applePay');
  }

  processApplePayV3(applePaymentData: ApplePaymentData, appId: string, locationId: string) {
    this.applePaymentData$.next(applePaymentData);
    this.squareAppId$.next(appId);
    this.squareLocationId$.next(locationId);
    this.checkIfMobileApp();
    if (this.isMobile && this.isMobileApp && this.paymentService.request.action == PaymentAction.PLACEORDER) {
      parent.postMessage(
        {
          action: 'initiate_apple_pay',
          paymentData: JSON.stringify(this.applePaymentData$.getValue()),
          etaMessage: this.paymentService.getEtaMessage(),
          orderType: this.paymentService.getOrderType()
        }, 
        sessionStorage.getItem("hostingapp")
      );
    }  
    else if (this.isMobile && this.isMobileApp && this.paymentService.request.action == PaymentAction.LOADWALLET) {
      parent.postMessage(
        {
          action: 'initiate_apple_pay_wallet',
          paymentData: JSON.stringify(this.applePaymentData$.getValue()),
          etaMessage: this.paymentService.getEtaMessage(),
          orderType: this.paymentService.getOrderType()
        }, 
        sessionStorage.getItem("hostingapp")
      );
    }
    else {
      this.processApplePay(applePaymentData, appId, locationId);
    }  
  } 

  openPaymentModal(applePaymentData: ApplePaymentData, appId: string, locationId: string, paymentType: string) {
    this.squareAppId$.next(appId);
    this.squareLocationId$.next(locationId);
    const modalRef = this.modalService.open(SquarePaymentPopupComponent);
    modalRef.componentInstance.squareAppId = this.squareAppId$.getValue();
    modalRef.componentInstance.squareLocationId = this.squareLocationId$.getValue();
    modalRef.componentInstance.paymentType = paymentType;
    modalRef.componentInstance.applePaymentData = applePaymentData;
  }

  goToNewTab(applePaymentData: ApplePaymentData, appId: string, locationId: string, paymentType: string) {  
    this.squareAppId$.next(appId);
    this.squareLocationId$.next(locationId);
    const timestamp = new Date().getTime();
    
    const parentWidth = window.outerWidth;
    const parentHeight = window.outerHeight;
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    let windowHeight = 500;
    let windowWidth = 500;
    if (applePaymentData.fees && applePaymentData.fees.length > 0) {
       windowHeight = 600
    }
    const parentLeft = (window.screen.width/2)-(windowWidth/2) + dualScreenLeft;
    const parentTop = (window.screen.height/2)-(windowHeight/2) + dualScreenTop; 

    let orderAppOrigin = environment.ONLINE_ORDER_URL;
    let url = `${environment.SQUARE_WALLET_PAY_END_POINT}?appid=${appId}&locationid=${locationId}&paymentType=${paymentType}&websiteURL=${environment.SQUARE_WALLET_PAY_END_POINT}&parentWidth=${parentWidth}&parentHeight=${parentHeight}&parentLeft=${parentLeft}&parentTop=${parentTop}&originUrl=${orderAppOrigin}&_=${timestamp}`;  
    let popup = this.popupCenter(url, paymentType, windowWidth, windowHeight, parentLeft, parentTop);   

    timer(1000).subscribe(x => {
        console.log('Posting Message');
        const msg = { applePaymentData: applePaymentData };
        popup.postMessage(msg, environment.SQUARE_WALLET_PAY_END_POINT);           
    });
  }

  popupCenter(url, title, w, h, parentLeft, parentTop) { 
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + parentTop + ', left=' + parentLeft);
  }

  public processPayment(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.squareToken$.getValue()) {      
        let squar3DSEnabled = this.applePaymentData$.getValue().merchantCapabilities.length > 0 ? true : false;	 
        this.paymentService.request.saveCreditCard = false;
        this.paymentService.request.payWithSavedCreditCard = false;
        this.paymentService.request.isApplePay = true;
        this.paymentService.request.isGooglePay = false;
        if (squar3DSEnabled) {
          const verificationToken = this.squareVerificationToken$.getValue();
          if (!verificationToken) {
            return reject(new Error("Square 3DS is enabled, but verification token is missing."));
          }
          this.paymentService.request.token = this.squareToken$.getValue();
          this.paymentService.request.tempNotes = this.squareToken$.getValue();
          this.paymentService.request.squareVerificationToken = verificationToken;
          this.paymentService.request.tempNotes += " " + verificationToken + " " + this.squareToken$.getValue();       
        }   
        else {
          this.paymentService.request.token = this.squareToken$.getValue();       
        }    
        console.log(this.paymentService.request);      
        await this.paymentService.processPayment().then(() => {
          resolve(true)
        }, error => {
          resolve(false);
        });               
      }
      else 
      {
        resolve(false);
      }      
    });
  }

  setSquarePaymentToken(squareToken, squareVerifiacationToken) {
    this.squareToken$.next(squareToken);
    this.squareVerificationToken$.next(squareVerifiacationToken);
  }
  
}