import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { AssetService } from './merchant-assets/asset.service';
import { Assets } from './merchant-assets/assets.model';
import { UtilService } from './util.service';

declare let gtag: Function;
@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  private merchantAssets: Assets;
  private merchantGoogleAnalyticsMesurmentId: string;
  private renderer2: Renderer2;
  private scriptsLoaded: boolean = false;
  private scriptGTMLoaded: boolean = false;

  constructor(private rendererFactory2: RendererFactory2, 
    private util: UtilService,
    @Inject(DOCUMENT) private _document: Document) 
    {
      this.renderer2 = this.rendererFactory2.createRenderer(null, null);
    }

  loadGoogleTagManager() 
  {
    var ma = this.util.getMerchantAssets();
    ma.then((assets) => {
      this.merchantAssets = assets
    });

    if(this.merchantAssets && this.merchantAssets.googleTagManagerAppId) 
    {    
      var googleTagManagerAppId = sessionStorage.getItem("googleTagManagerAppId");      
      var googleAnalyticsMesurementId = sessionStorage.getItem("googleAnalyticsMesurementId");

           
      if((window as any).dataLayer) {     
        /* FOR GTM TRACKING ONLY - CUSTOM TAGS BY MERCHANTS */
        if(googleTagManagerAppId !== undefined &&  googleTagManagerAppId !== null)  
        {
          if (!this.scriptGTMLoaded) {
            this.scriptGTMLoaded = true;
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j: any = d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', `${googleTagManagerAppId}`);       
          }         
        }
       
        /* FOR GTA TRACKING ONLY - MERCHANT CAN VIEW THEIR ON THEIR GA */
        if(googleAnalyticsMesurementId !== undefined &&  googleAnalyticsMesurementId !== null) 
        {
          this.merchantGoogleAnalyticsMesurmentId = googleAnalyticsMesurementId;
          console.log('FOR GTA TRACKING ONLY - MERCHANT CAN VIEW THEIR ON THEIR GA')
          if (!this.scriptsLoaded) {
            this.scriptsLoaded = true;
            (function() {
              const head = document.getElementsByTagName("head")[0];
              var myScript = document.createElement('script');
              myScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsMesurementId}`);
              myScript.onload = function() {
                (window as any).dataLayer = (window as any).dataLayer || [];
                const gtag: Gtag.Gtag = function () {(window as any).dataLayer.push(arguments);};
                gtag('js', new Date());
                gtag('config', googleAnalyticsMesurementId);               
                console.log('window.dataLayer Executed', (window as any).dataLayer)
              }
              head.insertBefore(myScript, head.children[1]);
            })();                    
          }
        }          
      }     
    }
  }

  get googleTagManagerAppIdAvailable():boolean{
    if(sessionStorage.getItem('googleTagManagerAppId')) 
    {
      return true;
    }
    else 
    {
      return false;
    }
  }

  sendEvent(action, value, formLocation) 
  {    
    if(this.googleTagManagerAppIdAvailable)  
    {
      try {
        if((<any>window).dataLayer) 
        {
          (<any>window).dataLayer.push({
            'event': action,
            'formLocation': formLocation,          
            'value': value
          });
        }     
      } catch (error) {
        console.log("GTM Error => event not tracked for : " + action)
        console.log(error)
      }     
    }   
  }

  trackSinglePageView(event: NavigationEnd) {
    if (this.merchantGoogleAnalyticsMesurmentId && this.scriptsLoaded) {
      gtag('config', this.merchantGoogleAnalyticsMesurmentId, { page_path: event.urlAfterRedirects });
    }
  }

  trackEvent(
      
      { eventName, eventCategory, eventAction, eventLabel, eventValue, eventLocation, eventApp, currency} = {
      eventName: null,
      eventCategory: null,
      eventAction: null,
      eventLabel: null,
      eventValue: null,
      eventLocation: null,
      eventApp: null,
      currency: null
    },
  ) {
    if (this.merchantGoogleAnalyticsMesurmentId && this.scriptsLoaded) 
    {      
      gtag('event', eventName, {
        eventCategory,
        eventLabel,
        eventAction,
        eventValue,
        eventLocation,
        eventApp,
        currency
      });
    }    
  }

  private insertMainScript() {
    if (this.merchantGoogleAnalyticsMesurmentId) {
      const script: HTMLScriptElement = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.onload = this.insertSecondHalfOfScript.bind(this);
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.merchantGoogleAnalyticsMesurmentId}`;
      script.text = '';
      this.renderer2.appendChild(this._document.body, script);
    }
  }

  private insertSecondHalfOfScript() {
    const script: HTMLScriptElement = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = '../assets/google-analytics-script.js';
    script.text = '';
    this.renderer2.appendChild(this._document.body, script);
    script.onload = () => {
      this.scriptsLoaded = true;
    };
  }
}
