import { PaymentAction } from './enum';
import { OrderPayment } from './order-payment';
import { GuestDetails } from '../models/guest-details.model';

export class ProcessPaymentRequest {
    action: PaymentAction;
    amount: number;
    currencySymbol: string;

    token: string;
    squareVerificationToken: string;
    tempNotes: string;
    cardNumber: string;
    cardCVV: string;
    cardExpirationMonth: number;
    cardExpirationYear: number;
    cardHolderName: string;
    saveCreditCard: boolean = false;

    giftCardId: string;
    giftCardPin: string;
    giftCardBalance: number;

    payWithSavedCreditCard: boolean = true;
    applyWallet: boolean;
    applyGiftCard: boolean;
    postalCode: string;
    countryCode: string;

    isGooglePay: boolean;
    isApplePay: boolean;
    applePayToken: string;
    applePayPaymentId: string;
    applePayApplicationData: string;

    guestDetails: GuestDetails = null;
}