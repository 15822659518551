import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable, BehaviorSubject, Subscriber, timer } from 'rxjs';
import { CartService } from '../cart.service';
import { CatalogOptionComponent } from '../catalog-option/catalog-option.component';
import { CatalogService } from '../catalog.service';
import { Catalog } from '../models/catalog';
import { AlertController, IonContent, IonSlides, LoadingController } from '@ionic/angular';
import { GoogleAnalyticService } from '../google-analytic.service';
import { OrderRoutingService } from '../order-routing.service';
import { MerchantHeaderComponent } from '../merchant-header/merchant-header.component';
import { DataService } from '../data.service';
import { CustomerSessionService } from '../customer-session.service';
import { CatalogLocation } from '../models/catalog-location';
import { CatalogTracked } from '../common/catalogTracked';
import { tap } from 'rxjs/operators';
import { UtilService } from '../util.service';
import { CatalogsTimeFrames } from '../models/catalogs-time-frames';
import { CategoryTrackedService } from '../common/category-tracked.service';
import { PropertyReader } from '../common/property-reader';
import { NgxSpinnerService } from 'ngx-spinner';
import { GoogleTagManagerService } from '../google-tag-manager.service';
import { FacebookPixelService } from '../facebook-pixel.service';
import { AssetService } from '../merchant-assets/asset.service';
import { ModalLocationConfirmationComponent } from '../modals/modal-location-confirmation/modal-location-confirmation.component';
import { TranslateService } from '@ngx-translate/core';
import { SquareApplePayService } from '../square-apple-pay.service';
import { environment } from 'src/environments/environment';


declare const $: any;
@Component({
    selector: 'catalog',
    templateUrl: './catalog.component.html',
    styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit, PropertyReader {

    @ViewChild(CatalogOptionComponent) catalogOptions;
    @ViewChild(MerchantHeaderComponent) merchantHeader;
    @ViewChild("locationConfirmationModal") LocationConfirmationModal: ModalLocationConfirmationComponent;

    headerbarHeight: number;
    catalog: Catalog;
    cartCount: number = 0;
    catalogUpdated: Subscription;
    selectedCategoryId: number = 0;
    showFilters: boolean = false;
    cartTotal: number = 0.00;
    categoriesTacker: CatalogTracked[] = [];
    /* Catalog Locations */
    locations: CatalogLocation[];
    selectedLocation: CatalogLocation;
    selectedLocationName: string;
    subCategoryViewOpen: boolean = false;
    catalogHasImage: boolean = false;
    recentOrders: any;
    currencySymbol: any;
    placeHolderImageUrl: string = '/assets/imgs/placeHolder.png';

    selectedCategoryTree: any[] = [];
    selectedCategory: any;

    selectedCatalogId: number;
    selectedCatalogName: string;

    public allCategories: BehaviorSubject<any[]> = new BehaviorSubject([]);

    isComponentLoading: boolean = false;
    catalogsTimeFrames: CatalogsTimeFrames[];


    scrollDuration = 200;
    paddleMargin = 20;
    menuWrapperSize: number;
    itemsLength: number;
    itemSize: number;
    menuSize: number;
    menuVisibleSize: number;
    menuInvisibleSize: number;
    menuPosition: number;
    menuEndOffset: number;
    selectedCategoryName: any;  
    @ViewChild(IonContent) content: IonContent;
    CURRENT_VIEW_ENTERED = "catalog_css";


    constructor(private cartService: CartService,
        private catalogService: CatalogService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private alertController: AlertController,
        private ga: GoogleAnalyticService,
        private orderRouting: OrderRoutingService,
        private dataService: DataService,
        private utilService: UtilService,
        private spinner: NgxSpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
        private categoryTrackedService: CategoryTrackedService,
        public loadingCtrl: LoadingController,
        private customerSession: CustomerSessionService, 
        private assetService: AssetService,
        private gtmService: GoogleTagManagerService,
        private fbPixelService: FacebookPixelService,
        private translate: TranslateService,
        private squareApplePayService: SquareApplePayService) {
            categoryTrackedService.registerCatalogComponent(this);
       
    }

    async ionViewWillEnter() { 
        this.switchCategory(-1);
        sessionStorage.removeItem("backToGuestOrderStatus");    
        this.utilService.postAppSpecificRequestsToFrame();          
         /* LOAD CUSTOM STYLE IF PROVIDED - <param - component name> */     
        if (sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) && sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) !== "") {
            this.assetService.getCustomCSS(sessionStorage.getItem(this.CURRENT_VIEW_ENTERED), this.CURRENT_VIEW_ENTERED);
            console.log("Loading Custom CSS for Catalog");
        } 
        this.isComponentLoading = true;      
        this.spinner.show();
        await this.initializeCatalogComponent().then(async (result) => {
            if (result) {
                console.log("initializeCatalogComponent => " + result);
                await this.getCatalogLocationsPromise()
                .then((locationResult) => {
                    if (locationResult) {
                        console.log("getCatalogLocationsPromise => " + result); 
                        this.isComponentLoading = false;
                        this.changeDetectorRef.detectChanges();
                        this.spinner.hide();      
                        
                        if (this.LocationConfirmationModal && 
                            sessionStorage.getItem("show_location_confirmation_popup") &&
                            !this.cartService.isCorrectOrderLocation) {
                            this.LocationConfirmationModal.confirmCorrectLocation();
                        }                               
                    }
                });
            }                        
        }).then(() => {
            $(document).ready(() => {              
               
                console.log(`this.selectedCategoryId => ${this.selectedCategoryId}`);
                this.setCategoryLabelSelected(this.selectedCategoryId);

                $('.secondary_nav_menu').on('scroll', (e) => {                         
                    this.calculateMenuSize();
                });   
                        
                $('.sticky_horizontal').stick_in_parent({ offset_top: 0 });
               
                this.headerbarHeight = $("#headerbar").outerHeight();
                console.log(`Header Bar Height => ${this.headerbarHeight}`);

                this.calculateMenuSize();
                this.showPaddels();
                $(window).on('resize', () => {           
                    this.showPaddels();
                });  

                window.addEventListener("orientationchange", (e) => {
                   this.scrollToTop();                  
                });

                const slider: any = document.querySelector('.secondary_nav_menu');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e: any) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                 });

                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });

                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if(!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 0.5;
                    slider.scrollLeft = scrollLeft - walk;
                });

                if(!sessionStorage.getItem("lead_submission") || 
                    sessionStorage.getItem("lead_submission") != this.selectedLocation.businessDetails.name || 
                    !sessionStorage.getItem("lead_submission_timestamp") ||
                    this.checktimestampDifferenceLead(sessionStorage.getItem("lead_submission_timestamp"))) {                    
                    this.gtmService.sendEvent('Lead', this.selectedLocation.businessDetails.name, 'catalog page');
                    this.fbPixelService.sendLeadEvent('Lead', this.selectedLocation.businessDetails.name); 
                    sessionStorage.setItem("lead_submission", this.selectedLocation.businessDetails.name);
                    var teadtimestamp = new Date();
                    sessionStorage.setItem("lead_submission_timestamp", teadtimestamp.toString());
                    console.log("set new lead");
                }
            });
        });
    }

    correctLocationSelected() {
        this.cartService.isCorrectOrderLocation = true;
    }

    incorrectLocationSelected() {
        this.orderRouting.goToLocation();
    }
  
    /* Check leadTimestamp Difference */
    checktimestampDifferenceLead(timestamp: any) {
        if (timestamp) {
            var stampedtime = new Date(timestamp).getTime(); 
            var currentTime = new Date().getTime();
            if( isNaN(stampedtime) )
            {
                return true;
            }
            
            var diff = Math.abs(stampedtime - currentTime) / 3600000;
            console.log(diff);
            return diff > 1;
        }
        else {
            return true;
        }
    }

    /* Menu Slider Code */
    getMenuWrapperSize() {
        return $('.secondary_nav_wrapper').outerWidth();
    }

    getMenuSize() {
        return this.itemsLength * 100;
    }

    getMenuPosition = () => {
        return $('.secondary_nav_menu').scrollLeft();
    };

    scrollToTop() {
        if (this.content) {
            this.content.scrollToTop(400);    
        }        
    }

    /* Clean up - Avoid placing logic inside ngOninit - known bug with ionic 4 - 
    * component ges cached and theis method is not called always
    */
    ngOnInit() {                    
       
    }

    showPaddels() {
        var leftPaddle = document.getElementsByClassName('left-paddle');
        var rightPaddle = document.getElementsByClassName('right-paddle'); 
        let divWidth = $('.secondary_nav_menu').width(); 
        let totalSize = this.getMenuSize() + this.paddleMargin; 
        $(rightPaddle).addClass('hidden');
        $(leftPaddle).addClass('hidden');
        if ($('.secondary_nav_menu').width() > 0) {

            if (Number(totalSize) >= Number(divWidth)) {
                $(rightPaddle).removeClass('hidden');
                $(leftPaddle).removeClass('hidden');
                console.log(`${totalSize} >= ${divWidth}`);
                console.log("visible right");
                $("#all-item").addClass("all-item");
            }
            if (Number(totalSize) <  Number(divWidth)) {
                $(rightPaddle).addClass('hidden');
                $(leftPaddle).addClass('hidden');
                console.log(`${totalSize} < ${divWidth}`);
                console.log("hidden right");
                $("#all-item").removeClass("all-item");
            } 
            
        } else {
            setTimeout(() => {
             this.showPaddels();
            }, 500);
        }
        
    }

    paddleClick(pos: any) {

        this.calculateMenuSize();

        if (pos == 'right') {
            let pos =  this.getMenuPosition() + this.itemSize;
            $('.secondary_nav_menu').animate( { scrollLeft: pos}, this.scrollDuration);    
        } else {
            let pos =  this.getMenuPosition() - this.itemSize;
            $('.secondary_nav_menu').animate( { scrollLeft: pos }, this.scrollDuration);
        }
       this.scrollMenu();
    }

    calculateMenuSize() {         
        this.menuWrapperSize = this.getMenuWrapperSize();
        this.itemsLength = $('.menu-item').length;
        this.itemSize = $('.menu-item').outerWidth(true);
        this.menuSize = this.getMenuSize();
        this.menuVisibleSize = this.menuWrapperSize;
        this.menuInvisibleSize = this.menuSize - this.menuWrapperSize;
        this.menuPosition = this.getMenuPosition();   
        this.menuEndOffset = this.menuInvisibleSize - this.paddleMargin; 
        this.scrollMenu();
     
    }


    scrollMenu() {
        $('.sticky_horizontal').stick_in_parent({ offset_top: 0 });       
    }
    
    onScrolling(event) { 

        this.headerbarHeight = $("#headerbar").outerHeight();

       if (event.detail.scrollTop >= this.headerbarHeight) {
        $('.sticky_horizontal').addClass('is_stuck');
        $('.is_stuck').css('top', this.headerbarHeight);
       }
       else {
        $('.is_stuck').css('top', 0);
        $('.sticky_horizontal').removeClass('is_stuck');
       }   
       console.log(`Header Bar Height => ${this.headerbarHeight}`);  
      // $('.sticky_horizontal').stick_in_parent({ offset_top: 0 });   
    }

   
    /* Changes - Making methods promisible to wait till request is complete 
    * This helps to wait for the promise to resolve and then we can execute tasks in order
    */

    initializeCatalogComponent(): Promise<any> {
        return new Promise<any>((resolve, reject) => {           
            this.utilService.documentActivatedRoute(this.activatedRoute);
            let askedForOrderType = this.cartService.askedForOrderType;        
            let cartCount = this.cartService.getCartCount();
            let isReloadRequired = this.catalogService.reloadCatalog;

            if (this.catalogService.getCatalogChangeRequested() == true) {
                this.cartService.askedForOrderType = false;
                askedForOrderType =   this.cartService.askedForOrderType;
            }

            this.catalogService.setCatalogChangeRequested(false);

            /*[DERPRECATED] => Should not force to pick order type */
            // if (cartCount >= 1 && !askedForOrderType) {
            //     this.orderRouting.goToOrderTypePicker();
            //     return;
            // }  
            // else
            // {
            //     this.catalogsTimeFrames = this.cartService.getCatalogsTimeFrames(); 

            //     if(sessionStorage.getItem("placeHolderImgUrl") && sessionStorage.getItem("placeHolderImgUrl") != "") 
            //     {
            //         var splitArray = sessionStorage.getItem("placeHolderImgUrl").split(sessionStorage.getItem("guid"));
            //         var sizeParameter = "100xAUTO";
            //         var new_url = splitArray[0] + `${sessionStorage.getItem("guid")}/` + sizeParameter + splitArray[1];
            //         this.placeHolderImageUrl = new_url;
            //     }       
        
            //     this.cartService.getCartSubTotal.subscribe(result => {
            //         this.cartTotal = result
            //     });
        
            //     isReloadRequired = this.catalogService.reloadCatalog;
            // }

            // check if a auto add item was added but no modifier was updated on item
            this.handleAutoAddItemWithModifiers();

            this.catalogsTimeFrames = this.cartService.getCatalogsTimeFrames(); 

            if(sessionStorage.getItem("placeHolderImgUrl") && sessionStorage.getItem("placeHolderImgUrl") != "") 
            {
                var splitArray = sessionStorage.getItem("placeHolderImgUrl").split(sessionStorage.getItem("guid"));
                var sizeParameter = "100xAUTO";
                var new_url = splitArray[0] + `${sessionStorage.getItem("guid")}/` + sizeParameter + splitArray[1];
                this.placeHolderImageUrl = new_url;
            }       
    
            this.cartService.getCartSubTotal.subscribe(result => {
                this.cartTotal = result
            });
    
            isReloadRequired = this.catalogService.reloadCatalog;
        
            console.log("CATALOG OPTION INIT FUNCTION CALLED");
            this.loadDataPromise(isReloadRequired).then((result) =>   {
                if (result) { 
                this.catalogService.reloadCatalog = false;
                this.selectedCatalogId = this.catalogService.getLastSelectedCatalogId();    
                resolve(true);
            }
            }, error => {   
            console.log(error);
            });
        });
    }

    async handleAutoAddItemWithModifiers() {
        if(sessionStorage.getItem("autoAddItemWithModifierIndex")) {
            let itemIndex = JSON.parse(sessionStorage.getItem("autoAddItemWithModifierIndex"));
            this.spinner.show();
            if (await this.cartService.removeFromCart(itemIndex, true) != true) {        
              this.spinner.hide();
              return;
            }
            this.spinner.hide();
            this.ga.sendEvent('menu_item_removed_from_cart', '');
            sessionStorage.removeItem("autoAddItemWithModifierIndex");
        }
    }

    getCatalogLocationsPromise(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.dataService.getCatalogLocations().subscribe((data) => {
                if (data) {
                    this.locations = data;
                    if (this.locations != undefined && this.locations != null) {
                        for (let location of this.locations) {
                            if (location.merchantLocationId == Number(sessionStorage.getItem("locationId"))) {
                                sessionStorage.setItem("selected_location_details", JSON.stringify(location));
                                this.selectedLocation = location;
                                this.selectedLocationName = location.businessDetails.name + " - " + location.businessDetails.address;
                                resolve(true);
                            }
                        }
                    }                   
                }            
          }, error => {
            reject(error);
          });
        });
    }   

    loadDataPromise(refresh = false): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            console.log("CATALOG OPTION INIT FUNCTION CALLED");
            this.catalogService.getCatalogsPromise(this.customerSession.locationId, refresh).then((result) =>   {
                if (result) {

                 this.catalog = this.catalogService.getCurrentCatalog();                   
                 this.selectedCatalogName =  this.catalog.name;  
                 if(this.catalog.locationSetting && this.catalog.locationSetting.currencySymbol) 
                 {
                     this.catalogService.currencySymbol = this.catalog.locationSetting.currencySymbol;
                 }
                 this.currencySymbol = this.catalogService.currencySymbol;
                 this.catalogHasImage = (<any>this.catalog).hasImage;
                 this.selectedCategoryId = this.catalogService.lastSelectedCategoryId;               


                 this.cartService.getCartSubTotal.subscribe(result => {
                     this.cartTotal = result
                 });
                 this.cartCount = this.cartService.getCartCount();
                 if (this.catalog.categories != undefined) {
                     console.log(this.catalog.categories)
                     this.showFilters = this.catalog.categories.length > 1 ? true : false;

                     console.log("Selected cat id " + this.selectedCategoryId);

                     this.translateCommonCategoriesName();

                 } else {
                     this.showFilters = false;
                 }                
                 
                resolve(true);
               }
            }, error => {   
              console.log(error);
            });
        });
    }
    /* New changes End */

    /* [Deprecated] => Use initializeCatalogComponent  */
    async initComponent() {
        this.isComponentLoading = true; 
        console.log("CATALOG OPTION INIT FUNCTION CALLED");
       
        const delay = (ms: number) => new Promise(res => setTimeout(res, ms)); 
        await delay(1500).then(() => {
            this.loadData().toPromise().then(async (result) =>   {
                if (result) {   
                   this.isComponentLoading = false;              
               }
            }, error => {
              this.isComponentLoading = false;    
              this.utilService.hideLoadingMessage();
            });
        });        
    }

    /* [Deprecated] => Use loadDataPromise */
    loadData(refresh = false) {
        return this.catalogService.getCatalogs(this.customerSession.locationId, refresh)
            .pipe(
                tap((catalogs) => {

                    this.catalog = this.catalogService.getCurrentCatalog();                   
                    this.selectedCatalogName =  this.catalog.name;  
                    if(this.catalog.locationSetting && this.catalog.locationSetting.currencySymbol) 
                    {
                        this.catalogService.currencySymbol = this.catalog.locationSetting.currencySymbol;
                    }
                    this.currencySymbol = this.catalogService.currencySymbol;
                    this.catalogHasImage = (<any>this.catalog).hasImage;
                    this.selectedCategoryId = this.catalogService.lastSelectedCategoryId;               


                    this.cartService.getCartSubTotal.subscribe(result => {
                        this.cartTotal = result
                    });
                    this.cartCount = this.cartService.getCartCount();
                    if (this.catalog.categories != undefined) {
                        console.log(this.catalog.categories)
                        this.showFilters = this.catalog.categories.length > 1 ? true : false;

                        console.log("Selected cat id " + this.selectedCategoryId);

                        this.translateCommonCategoriesName();

                    } else {
                        this.showFilters = false;
                    }                
                    })
            )
    }

    changeCatalog() 
    {
       this.catalogService.setCatalogChangeRequested(true);
       this.orderRouting.goToCatalogPicker();
    }


    getCatalogLocations() {
        /* Get Catalog Locations */
        this.dataService.getCatalogLocations().subscribe(async (data) => {
            if (data) {
                this.locations = data;
                if (this.locations != undefined && this.locations != null) {
                    for (let location of this.locations) {
                        if (location.merchantLocationId == Number(sessionStorage.getItem("locationId"))) {
                            sessionStorage.setItem("selected_location_details", JSON.stringify(location));
                            this.selectedLocation = location;
                        }
                    }
                }
            }
        });
    }

    catalogInit() {
            console.log("catalogInitFunction");
        this.catalogUpdated = this.catalogService.catalog.subscribe(async (catalog) => {
            if (catalog) {
                this.catalog = JSON.parse(JSON.stringify(catalog));
               
                if(this.catalog.locationSetting && this.catalog.locationSetting.currencySymbol) 
                {
                    this.catalogService.currencySymbol = this.catalog.locationSetting.currencySymbol;
                }
                this.currencySymbol = this.catalogService.currencySymbol;
                this.catalogHasImage = (<any>this.catalog).hasImage;
                this.selectedCategoryId = this.catalogService.lastSelectedCategoryId;               

                //this.cartService.setCartSubTotal();
                this.cartService.getCartSubTotal.subscribe(result => {
                    this.cartTotal = result
                });
                this.cartCount = this.cartService.getCartCount();
                if (this.catalog.categories != undefined) {
                    console.log(this.catalog.categories)
                    this.showFilters = this.catalog.categories.length > 1 ? true : false;

                    console.log("Selected cat id " + this.selectedCategoryId);

                    this.translateCommonCategoriesName();

                } else {
                    this.showFilters = false;
                }                
            }
        });
    }

    resetCart() {       
        this.cartService.getCartSubTotal.subscribe(result => {
            this.cartTotal = result
        });
        this.cartService.clearCart();
    }

    openCategory(category) {        
        this.selectedCategoryTree.push(category);
        this.selectedCategory = category;
        this.subCategoryViewOpen = true;        
    }    

    async alertUnavailable() {
        const alert = await this.alertController.create({
            header: this.translate.instant('Oops'),
            message: this.translate.instant("Unfortunately this location isn't accepting mobile orders at this time."),
            buttons: [this.translate.instant('OK')]
        });
        await alert.present();
    }

    segmentChangedLarge(value) {
        $('.list-item-button').css('font-weight', 'normal');
        $('#btn' + value).css('font-weight', '700');
        this.catalogService.lastSelectedCategoryId = value;
        this.selectedCategoryId = this.catalogService.lastSelectedCategoryId;
    }

    segmentChanged(ev: any) {
        this.catalogService.lastSelectedCategoryId = ev.detail.value;
        this.selectedCategoryId = this.catalogService.lastSelectedCategoryId;
    }

    ionViewDidEnter() {
        try {
         
            console.log("LOOKING AT THE MENU");
            this.cartCount = this.cartService.getCartCount();
            if (this.cartCount > 0 && this.cartTotal == 0) {
                this.cartService.calculateCartPrice();
            }
            
            if (this.merchantHeader) {
                this.merchantHeader.initComponent();
            }            
    
            if (this.catalogService.refreshCatalogOptions) {
                this.catalogService.refreshCatalogOptions = false;
                console.log('refresh catalog options');
                this.loadData(true).subscribe();
            }
    
            this.ga.sendEvent('menu_viewed', '');
            
        } catch (error) {
            console.log(error);
        }

        this.scrollToTop();

    }

    async doRefresh(event) {
        timer(500).subscribe(async r => {
            this.catalogService.reloadCatalog = true;
            await this.ionViewWillEnter();
        });
    }

    goToCart() {       
        let cartHasItems = this.cartService.getCartCount() > 0;

        if (!cartHasItems) {
            this.utilService.showSimpleAlert(this.translate.instant("Please add item to cart"));
        }
        else
        {
            //cart has to be in local storage
            this.orderRouting.goToCart();
        }       
    }

    askForFutureOrder() {
        if (this.cartService.askedForFutureOrder == false) {
            this.dataService.GetOrderAheadSetting(this.customerSession.locationId).subscribe(
                (data) => {
                    this.cartService.askedForFutureOrder = true;

                    if (data.orderAheadEnabled == true) {
                        this.cartService.orderAheadEnabled = true;
                    } else {
                        this.cartService.orderAheadEnabled = false;
                    }

                    if (data.curbSidePickUpEnabled == true) {
                        this.cartService.curbSidePickUpEnabled = true;
                    } else {
                        this.cartService.curbSidePickUpEnabled = false;
                    }

                    if (data.disableInStorePickUp == true) {
                        this.cartService.disableInStorePickUp = true;
                    } else {
                        this.cartService.disableInStorePickUp = false;
                    }

                    if (data.deliveryEnabled == true) {
                        this.cartService.deliveryEnabled = true;                        
                        this.cartService.minimumOrderTotalForDelivery = data.minimumOrderTotalForDelivery;
                    } else {
                        this.cartService.deliveryEnabled = false;
                    }

                    if (data.isExternalDelivery == true) {
                        this.cartService.isExternalDelivery = true;
                    } else {
                        this.cartService.isExternalDelivery = false;
                    }

                    if (data.catalogsTimeFrames) {
                        this.cartService.setCatalogsTimeFrames(data.catalogsTimeFrames);
                    }

                    if (this.cartService.orderAheadEnabled == true || this.cartService.curbSidePickUpEnabled || this.cartService.deliveryEnabled) {
                        this.orderRouting.goToOrderAhead();
                    }

                },
                (err) => {
                    console.log(err);
                }
            );
        }
    }

    addItem(item, categoryId: number = -1) {
        if (!item.soldOut) {
            console.log('go to item');
            // Check if recently ordered category (0)
            if(categoryId == 0) {
                this.orderRouting.goToRecentlyOrderedItem(item.id);
            } else {
                this.orderRouting.goToItem(item.id);
            }
        }
        // this.router.navigate(['item', item.id], {relativeTo: this.route});
    }

    clearCart() {
        this.cartService.clearCart();
        this.cartCount = 0;
    }

    ngOnDestroy() {
        console.log("CATALOG DESTROYED");
        if (this.catalogUpdated) {
            this.catalogUpdated.unsubscribe();
        }       
    }
   

    // Interface member needs to be implemented
    public readProperty<T>(prop: string): T {
        if (!this.hasOwnProperty(prop)) {
            throw Error(`Property "${prop}" does not exists`);
        }
        return this[prop];
    }

    public setProperty<T>(prop: string, val: any) {
        if (!this.hasOwnProperty(prop)) {
            throw Error(`Property "${prop}" does not exists`);
        }     
        this[prop] = val;      
    }

    public callOpenCategory(val: any) {
        this.openCategory(val);
    }

    public callResetCart() {
        this.resetCart();
    }

    public callAddItem(val: any) {
        this.addItem(val);
    }

    back() {        
        this.selectedCategoryTree.pop();
        this.selectedCategory = this.selectedCategoryTree[this.selectedCategoryTree.length-1];
        if (this.selectedCategoryTree.length == 0) {
            this.subCategoryViewOpen = false;
        }
    }

    catalogChanged() {
        this.subCategoryViewOpen = false;
        this.selectedCategoryTree = [];
        this.selectedCategory = null;    
    }

    /* Front-Menu UI Changes */
    switchCategory(catId: any) {
        console.log(catId);
        $(".secondary_nav ul li a").removeClass('selected-menu-item');
        this.setCategoryLabelSelected(catId);
        this.catalogService.lastSelectedCategoryId = catId;
        this.selectedCategoryId = this.catalogService.lastSelectedCategoryId;
        this.changeDetectorRef.detectChanges();  
    }

    setCategoryLabelSelected(catId: any) {
        if (catId == -1) {
            $(".secondary_nav ul li:first-child a").addClass('selected-menu-item');
            this.selectedCategoryName = this.translate.instant('All');
        }
        else {
            $(`#menu_item_${catId}`).addClass('selected-menu-item');
            this.selectedCategory = this.catalog.categories.find(x => x.id == catId);
            this.selectedCategoryName = this.selectedCategory.name;
        }
    }

    shrinkText(text: any) {
        let str = '';
        if (text && text.length > 55) {
           str =  text.substring(0, 55);
           str = str + '....';
           return str;
        }       
        else{
            return text;
        }        
    }

    translateCommonCategoriesName() {
        if (this.catalog && this.catalog.categories) {
            this.catalog.categories.forEach(c => {
                if (c.name == "Popular Items"
                    || c.name == "Recently Ordered") {
                    c.name = this.translate.instant(c.name);
                }
            });
        }
    }
   
}
